const { create } = require('zustand');

const useSearchGameBarStore = create((set, get) => ({
	openSearchGameBar: false,
	setOpenSearchGameBar: () => {
		set({ openSearchGameBar: !get().openSearchGameBar });
	},
	setCloseSearchGameBar: () => {
		set({ openSearchGameBar: false });
	}
}));

export default useSearchGameBarStore;
