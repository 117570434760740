import React from 'react';
import SearchComponent from '../../atom/SearchComponent/SearchComponent';
import useGameSearchComponentStore from './stores/useGameSearchComponentStore.store';
import { MotionComponent } from '../../atom/MotionComponent';
import useSearchGameBarStore from '../../organism/NavbarMobile/stores/useSearchGameBarStore.store';
import { useTranslation } from 'react-i18next';

const GameSearchComponent = ({ slideMode }) => {
	const { setOpenSearchGamePage, onSearch } = useGameSearchComponentStore((state) => ({
		setOpenSearchGamePage: state.setOpenSearchGamePage,
		onSearch: state.onSearch
	}));
	const { setCloseSearchGameBar } = useSearchGameBarStore((state) => ({
		setCloseSearchGameBar: state.setCloseSearchGameBar
	}));

	const onOpenSearchGamePageComponent = () => {
		setOpenSearchGamePage();
		setCloseSearchGameBar();
	};

	const { t } = useTranslation();

	return (
		<React.Fragment>
			<MotionComponent slideMode={slideMode}>
				<div className="mb-section">
					<div onClick={onOpenSearchGamePageComponent}>
						<SearchComponent
							gradientBorder
							placeholder={
								t('text.searchFavoriteGame') == 'text.searchFavoriteGame' // Use condition because local language not rendering as default language
									? 'cari game favorit anda'
									: t('text.searchFavoriteGame')
							}
							value={onSearch}
							readOnly
						/>
					</div>
				</div>
			</MotionComponent>
		</React.Fragment>
	);
};

export default GameSearchComponent;
