import React, { useCallback, useRef } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import BrandConfigStore from '@/context/store/brand-config';

const SearchComponent = ({
	value,
	onChange,
	onSearch,
	placeholder = 'Search',
	gradientBorder = false,
	disabled = false,
	bgColor = 'bg-container-bg-primary', // Assuming 'bg-primary-background' is a valid Tailwind class
	readOnly = false,
	justClick
}) => {
	const inputRef = useRef(null);

	const { colors } = BrandConfigStore((state) => state.config.themeConfig.container);

	const searchHandler = (e) => {
		e.preventDefault();
		if (onSearch) onSearch();
	};

	const clearInputHandler = useCallback(() => {
		if (onChange) {
			onChange('');
		}
		if (inputRef.current) {
			inputRef.current.focus();
		}
		if (onSearch) onSearch();
	}, [onChange, onSearch]);

	const onChangeHandler = (e) => {
		if (onChange) {
			onChange(e.target.value);
		}
	};

	const onJustClick = () => {
		if (justClick) {
			justClick();
		}
	};

	return (
		<React.Fragment>
			<form
				onSubmit={searchHandler}
				className="flex col-span-12 w-full h-9 font-semibold rounded-lg"
				onClick={onJustClick}>
				<div
					className={`
					flex w-full h-full rounded-lg items-center relative
					${gradientBorder ? 'game-search-input-wrapper' : 'border'}
				`}>
					<input
						ref={inputRef}
						type="text"
						value={value}
						className={`
						w-full h-full rounded-lg text-sm outline-none border-transparent focus:border-transparent focus:ring-0 px-3 text-container-color-primary
						placeholder:font-light
						${gradientBorder ? `${bgColor}` : 'bg-inherit'}
						`}
						placeholder={placeholder}
						disabled={disabled}
						readOnly={readOnly}
						aria-label="Search Input"
						onChange={onChangeHandler}
					/>
					<div className="absolute right-0 flex items-center space-x-2">
						{value && (
							<CloseOutlinedIcon
								className="text-gray-500 cursor-pointer hover:text-gray-700"
								onClick={clearInputHandler}
								aria-label="Clear Search"
							/>
						)}

						<button type="submit" className="px-2" aria-label="Search">
							<SearchIcon
								style={{
									color: colors.primary
								}}
							/>
						</button>
					</div>
				</div>
			</form>
		</React.Fragment>
	);
};

export default SearchComponent;
